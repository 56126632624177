import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import AdvertisementComponent from "./utilities/advertismentComponent";

const ResultChart = ({ dayGameData }) => {
  const [data, setGameData] = useState([]);
  const location = useLocation();
  const currentYear = moment().year();
  const currentTime = moment().format("HH:mm");
  const currentDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    setGameData(dayGameData);
  }, [dayGameData]);

  const getTodayResult = (gameData) => {
    const itemTime = moment(gameData.open_time, "HH:mm");
    const currentMoment = moment(currentTime, "HH:mm");

    if (gameData?.curr_date?.date === currentDate) {
      return currentMoment.isSameOrAfter(itemTime)
        ? gameData?.curr_date?.result || ""
        : "";
    }
    return "";
  };

  const isSpecialPage = ["/privacy-policy", "/disclaimer", "/about-us"].some(
    (path) => location.pathname.includes(path)
  );

  return (
    <div id="dayResult">
      {!isSpecialPage && (
        <div className="col-12 pt-5 result-chart-container">
          <h3 className="chart-heading">Satta King {currentYear} Chart</h3>
          <div className="row">
            {data.map((gameData, index) => (
              <div key={index} className="col-md-6 col-sm-12">
                <div className="col-lg-12 result-box">
                  <h6 className="game-name">{gameData?.game_name}</h6>
                  <p className="mb-0 fs-6 textColor game-time">
                    Time ({gameData.open_time})
                  </p>
                  <div className="d-flex align-items-end text-center  result-rows">
                    {/* <div className="col-4">
                      <p className="mb-0 fs-6 text-light">OLD</p>
                      <span className="btn btn-light">
                        {gameData?.old_date?.result || "--"}
                      </span>
                    </div> */}
                    <div className="col-4">
                      <p className="mb-0 fs-6 text-light">LAST</p>
                      <span className="btn btn-light">
                        {gameData?.prev_date?.result || "--"}
                      </span>
                    </div>
                    
                    <div className="col-4">
                      <p className="mb-0 fs-6 text-light">NEW</p>
                      <span className="btn btn-light">
                        {getTodayResult(gameData)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <AdvertisementComponent type="even" />
    </div>
  );
};

export default ResultChart;
