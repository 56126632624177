import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import Banner from './banner';
import InfoSection from './information';
import Footer from './footer';
import FooterDrop from './footerDrop';
import FooterButtonPage from './js/footerBottonPage';
import { BrowserRouter as Router } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  < Router >
    <div className='container-fluid bg-dark'>
      <App />
      {/* <Banner /> */}
      <FooterDrop />
      <InfoSection />
      <FooterButtonPage />
      <Footer />
    </div>
  </Router >
);

reportWebVitals();
